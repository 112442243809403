/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* src/index.css */
.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.75);
  }
  
  .react-confirm-alert {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .react-confirm-alert-button-group button {
    background-color: #1a202c; /* Tailwind's bg-gray-900 */
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    margin: 0 4px;
    border: none;
    cursor: pointer;
  }
  
  .react-confirm-alert-button-group button:hover {
    background-color: #4a5568; /* Tailwind's bg-gray-700 */
  }
  
  .recorder-container {
    width: 350px;
    padding: 2rem;
    background: white;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
    text-align: center;
    color: #2563eb;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
}

.controls {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.btn {
    flex: 1;
    padding: 0.75rem;
    border: none;
    border-radius: 0.5rem;
    background: #2563eb;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.send-btn {
    width: 100%;
    margin-top: 1rem;
    background: #16a34a;
}

.send-btn:hover {
    background: #15803d;
}

.send-btn:disabled {
    background: #94a3b8;
    cursor: not-allowed;
}

.btn:hover {
    background: #1d4ed8;
    transform: translateY(-1px);
}

.btn:active {
    transform: translateY(0);
}

.btn.recording {
    background: #dc2626;
    animation: pulse 1.5s infinite;
}

.btn:disabled {
    background: #94a3b8;
    cursor: not-allowed;
    transform: none;
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.7; }
    100% { opacity: 1; }
}

.timer {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: #1e293b;
    margin-bottom: 1rem;
    font-family: monospace;
}

.status {
    text-align: center;
    color: #64748b;
    font-size: 0.9rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-top: 1rem;
}

.status.error {
    background: #fee2e2;
    color: #dc2626;
}

.status.success {
    background: #dcfce7;
    color: #16a34a;
}

.audio-preview {
    margin-top: 1rem;
    width: 100%;
    display: none;
}

.recording-indicator {
    width: 100px;
    height: 100px;
    margin: 0 auto 1.5rem;
    border-radius: 50%;
    background: #e2e8f0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.recording-indicator.active {
    background: #fee2e2;
}

.recording-indicator.active::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #dc2626;
    opacity: 0.2;
    animation: expand 1.5s infinite;
}

@keyframes expand {
    0% { transform: scale(0.95); opacity: 0.2; }
    50% { transform: scale(1.1); opacity: 0.1; }
    100% { transform: scale(0.95); opacity: 0.2; }
}
